import { getSiteInfo } from '~/composables/siteInfo'
export enum roleLevel {
  超管 = 0,
  站长 = 1,
  运维 = 2,
  观察 = 3,
  来宾 = 99,
}
export const roleString: Record<number, string> = {
  0: '超管',
  1: '站长',
  2: '运维',
  3: '观察',
  99: '来宾',
}
export type UserInfo = {
  id: number
  openId: string
  nick: string
  avatarUrl: string
  role: number
  sites: number[]
  lastLogin: string
}
export const anonymous: UserInfo = {
  id: 0,
  openId: '',
  nick: '登录中',
  avatarUrl: '',
  role: roleLevel.来宾,
  sites: [],
  lastLogin: '',
}
export async function getUserInfo(): Promise<UserInfo> {
  const e = await fetch('/site-manage/userinfo', {
    method: 'GET',
  })
  const userInfo = await e.json()

  if (userInfo.role == roleLevel.超管) {
    userInfo.sites = (await getSiteInfo()).map(t => t.id)
  }
  return userInfo
}

export async function getUsers(): Promise<UserInfo[]> {
  const e = await fetch('/site-manage/user', {
    method: 'GET',
  })

  const arr = await e.json() as UserInfo[]
  arr.sort((a, b) => (a.role == b.role ? a.id - b.id : a.role - b.role))
  return arr
}

export type UserModule = {
  id: number
  site: number
  name: string
  resources: {
    kind: string
    accessAll: boolean
    accessible: number[]
  }[]
}

export async function getUserModules(id: number) {
  let url = '/site-manage/user-module'
  if (id > 0) {
    url += '?id=' + id
  }
  const e = await fetch(url, {
    method: 'GET',
  })
  const arr = await e.json() as UserModule[]
  return arr
}
