export type SiteInfo = {
  id: number
  name: string
  description: string
}
export async function getSiteInfo(): Promise<SiteInfo[]> {
  const e = await fetch('/site-manage/siteinfo', {
    method: 'GET',
  })
  return e.json()
}
